
import _ from 'lodash';
import { matchType as match } from '@mkrause/match';
import { updateRequest } from './request_util.js';

import Collection from '../models/Collection.js';


const mergeCollection = (updatedState, path, value, status) => {
    return updatedState.updateIn(path, (v) => {
        return (status === 'pending' || status === 'error')
            ? v.withStatus(status)
            : value;
    });
};

// Redux reducer. Takes API-related actions and updates the store accordingly.
export const update = (state, action) => match(action, {
    [match.default]: state,
    // Load data into the API (either new, or updates of existing data)
    'api.load': ({ requestId, status, path, value, params: { merge = false } = {} }) => {
        const updatedState = updateRequest({state, requestId, status});
        // Only setIn if the collection was invalid, for GET and LIST operations
        // && !(updatedState.getIn(path).status === 'invalid' && status === 'pending')
        return (updatedState.getIn(path))
            ? mergeCollection(updatedState, path, value, status)
            : updatedState.setIn(path, value)
    },
    
    // Clear data from the API
    'api.dispose': ({ path }) => {
        const current = state.getIn(path);
        if (_.isUndefined(current)) {
            return state;
        } else if (current instanceof Collection) {
            // Empty the collection
            return state.setIn(path, new Collection(current.type));
        } else {
            return state.removeIn(path);
        }
    },
});
